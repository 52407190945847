<template>
  <v-footer
    id="home-footer"
    color="#282828"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <!-- <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <a
                :key="s"
                class="white--text pa-1 pa-md-0"
                href="#"
                v-text="s"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col> -->
        <v-col
          class="text-center text-md-center text-caption"
          cols="12"
        >
          <a
            class="white--text mr-2"
            href="https://beian.miit.gov.cn"
          >备案号：沪ICP备2023016710号-1</a>
          Copyright &copy; 2021 - 2023 {{ $t('home.footer') }}
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
